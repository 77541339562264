<template>
  <div>
    <div class="flex-parent-view">
      <h1>Die Magenta Welt erleben</h1>
      <div class="layout-grid" v-if="notLarge">
        <div class="layout-grid_top">
          <swiper ref="topOptionsSlider" :options="topOptionsSlider">
            <swiper-slide v-for="(item, i) in filteredMainSlides" :key="'allSlidesI-' + i" >
              <router-link class="child" :to="{ path: item.name }">
                <div class="child-items">
                  <h2>
                    <span class="d-flex algin-items-center mr-4 mb-1" v-if="item.name === 'speed-check'">
                      <img src="@/assets/images/Icon-SpeedRechner-white.svg" alt="" />
                    </span>
                    <span v-html="item.title"></span>
                  </h2>
                </div>
              </router-link>
            </swiper-slide>

            <div class="swiper-button-prev custom-start" slot="button-prev"></div>
            <div class="swiper-button-next custom-start" slot="button-next"></div>
          </swiper>
        </div>
        <div class="layout-grid_bottom">
          <template v-for="(item, i) in filteredOtherSlides">
            <router-link class="child speed-check" :key="'allSlides-' + i" :to="{ path: item.name }">
              <div class="child-items">
                <h2>
                  <span class="d-flex algin-items-center mr-4 mb-1" v-if="item.name === 'speed-check'">
                    <img src="@/assets/images/Icon-SpeedRechner-white.svg" alt="" />
                  </span>
                  <span v-html="item.title"></span>
                </h2>
              </div>
            </router-link>
          </template>
        </div>
      </div>
      <div class="layout-grid" v-else>
        <template v-for="(item, i) in mainSlides">
          <template v-if="item.name && item.title && dynamicVisibility(item)">
            <div class="child hidden-item" v-if="mainSlides.length === 4 && i === 1" :key="'hiddenSlide-' + i">
              <div class="child-items"></div>
            </div>
            <router-link class="child" :class="{ 'speed-check': item.name === 'speed-check' || item.name === 'streaming-calculator' }" :key="'allSlides-' + i" :to="{ path: item.name }">
              <div class="child-items">
                <h2>
                  <span class="d-flex algin-items-center mr-4 mb-1" v-if="item.name === 'speed-check'">
                    <img src="@/assets/images/Icon-SpeedRechner-white.svg" alt="" />
                  </span>
                  <span v-html="item.title"></span>
                </h2>
              </div>
            </router-link>
          </template>
        </template>
      </div>
      <div class="checkpanel">
        <div class="rowInput">
          <p :class="{ active: !type }">pk</p>
          <label class="toggleCheckbox">
            <input v-model="type" type="checkbox" class="hiddenInput"/>
            <span></span>
          </label>
          <p :class="{ active: type }">gk</p>
        </div>
      </div>
      <div class="main-bottom-links" v-if="links">
        <template v-for="(item, i) in links" >
          <a :key="'a-link-' + i" :href="item.link" :target="item.is_external ? '_blank' : '_self'">{{ item.title }}</a>
          <span v-if="i != (Object.keys(links).length)" :key="'a-link-divider-' + i" class="menu-item-divider"></span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import slidesStore from '@/store/modules/slides'
import 'swiper/css/swiper.css'
const portraitDevice = window.matchMedia('(max-width: 1080px)')

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['page', 'mainSlides']),
    filteredSlides () {
      return this.mainSlides.filter(item => {
        return item.type === this.type
      })
    },
    filteredMainSlides () {
      let arr = []
      for (let itm in this.mainSlides) {
        let item = this.mainSlides[itm]
        if (item.name && item.title && !['speed-check', 'streaming-calculator'].includes(item.name)) {
          arr.push(item)
        }
      }
      return arr
    },
    filteredOtherSlides () {
      let arr = []
      for (let itm in this.mainSlides) {
        let item = this.mainSlides[itm]
        if (item.name && item.title && ['speed-check', 'streaming-calculator'].includes(item.name)) {
          arr.push(item)
        }
      }
      return arr
    },
    startSwiper () {
      return this.$refs?.startSwiper?.$swiper
    },
    notLarge () {
      return window.innerWidth < 1025
    }
  },
  data () {
    return {
      type: true,
      links: null,
      topOptionsSlider: {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next.custom-start',
          prevEl: '.swiper-button-prev.custom-start'
        },
        pagination: false,
        breakpoints: {
          768: {
            slidesPerView: 3
          },
          992: {
            slidesPerView: 4
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('slides', ['searchPage', 'clearSlide']),
    dynamicVisibility (item) {
      return true || (item.show_in_large && portraitDevice.matches)
    }
  },
  async created () {
    // self.searchMainSlides()
    this.type = this.$route.query.type === '2'
    let self = this
    await this.searchPage('').then(resp => {
      if (resp) {
        self.links = resp.data
      }
    })
  },
  mounted () {
    if (window.innerWidth > 1024) {
      this.startSwiper.destroy()
    }
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
